<template>
  <GuideStep v-if="reportStore.entityDetail?.guideStep < 2" type="GENERAL_LEDGER" />
  <div v-else v-loading.fullscreen.lock="loading">
    <Header
      ref="headerRef"
      type="GENERAL_LEDGER"
      :title="t('project.nav.generalLedger')"
      :updated-on="updatedTime ? t('common.updatedOn') + utils.dateFormatting(updatedTime) + ' UTC' : ''"
      :show-regenerate-button="true"
      :show-regenerate="true"
    />

    <ProcessTable ref="processTableRef" type="general-ledger" group-name="accountName" :height="tableHeight" />
  </div>
</template>
<script lang="ts" setup>
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import Header from '../components/Header.vue'
import GuideStep from '../components/GuideStep.vue'
import { useGlobalStore } from '@/stores/modules/global'
import ProcessTable from '../components/ProcessTable.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import { useReportsTableConfigStore } from '@/stores/modules/reports/tableConfig'

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()
const globalStore = useGlobalStore()
const { entityDetail } = storeToRefs(reportStore)
const tableConfigStore = useReportsTableConfigStore()
const loading = ref(false)
const processTableRef = ref()
const headerRef = ref()

const updatedTime = computed(() => {
  return entityDetail.value.updatedOn
})

const tableHeight = computed(() => {
  if (globalStore.showUpGradeInfo) {
    return headerRef.value?.isShowRegeneratedInfo ? `calc(100vh - 199px)` : `calc(100vh - 141px)`
  }
  return headerRef.value?.isShowRegeneratedInfo ? `calc(100vh - 168px)` : `calc(100vh - 110px)`
})

watch(
  route,
  async (newValue) => {
    loading.value = true
    if (newValue.name === 'reports-general-ledger') {
      tableConfigStore.processTableConfig = []
      await tableConfigStore.getProcessTableConfig('generalLedger')
      emitter.emit('resetList')
    }
    setTimeout(() => {
      loading.value = false
    }, 300)
  },
  { immediate: true, deep: true }
)
</script>
<style lang="scss" scoped></style>
